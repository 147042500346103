import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import { Button, Card } from "react-bootstrap";
import HeaderTop from "../../components/HeaderTop";
import HeaderMid from "../../components/HeaderMid";
import HeaderLow from "../../components/HeaderLow";
import MobileHead from "../../components/MobileHead";
import FormSub from "../../components/FormSub";
import FooterTop from "../../components/FooterTop";
import FooterMid from "../../components/FooterMid";
import Helmet from "react-helmet";
import closebtn from "./closebtn.png";
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import "./Productscreen.scss";
import { useDispatch } from "react-redux";
import {
  addtofavourite,
  removeFromfavourite,
} from "../../store/Actions/Favourite";
import { apiStart } from "../../apiurl/apiData";

export default function ProductScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { slug } = params;
  const navigate = useNavigate();

  const [product, setProduct] = useState("");
  const [productCont, setProductCont] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productId, setProductId] = useState("");

  const notify = (e) => toast(e);
  const addToFavHandler = (y, propimg, propid) => {
    const classactive = y.target.classList.value.includes("active");
    console.log(classactive);
    if (classactive) {
      notify("Removed From Wishlist!");
      y.target.classList.remove("active");
      dispatch(
        removeFromfavourite({
          id: propid,
        })
      );
    } else {
      notify("Added To Wishlist!");
      y.target.classList.add("active");
      dispatch(
        addtofavourite({
          id: propid,
          image: propimg,
          name: "",
        })
      );
    }
  };
  const existingFavItems = JSON.parse(localStorage.getItem("favItems"));

  let queryparams = new URLSearchParams(document.location.search);
  let page = parseInt(queryparams.get("page"), 10);

  const handlepageForward = (e) => {
    queryparams.set("page", page + 1);
    window.location.search = queryparams.toString();
  };
  const handlepageBackward = (e) => {
    queryparams.set("page", page - 1);
    window.location.search = queryparams.toString();
  };

  const { data, loading } = useFetch(
    `${apiStart}/api/products/category/${slug}?page=${page}&limit=16`
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {slug.replaceAll("-", " ").charAt(0).toUpperCase() +
            slug.replaceAll("-", " ").slice(1)}
        </title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="preload" as="style" href="./Productscreen.scss" />
      </Helmet>
      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>

      <main>
        <div>
          <div>
            <Header slug={slug} />
          </div>
          <div>
            <h5 className="with-stroke text-center">
              <span className="stroke-head">
                <span style={{ fontSize: "30px", color: "brown" }}>
                  {slug.replaceAll("-", " ").charAt(0).toUpperCase() +
                    slug.replaceAll("-", " ").slice(1)}
                </span>{" "}
                are Listed below
              </span>
            </h5>
            <h5 className="with-stroke text-center">
              <span className="stroke-head">Shop Now</span>
            </h5>
          </div>

          <div className="supreme productItem">
            {loading ? (
              <Loader />
            ) : data.length !== 0 ? (
              data.map((e, i) => (
                <Card key={i}>
                  <Card.Img
                    className="img-fluid lazy"
                    alt="I'm an image!"
                    onClick={() => {
                      setProductCont("layoutforproduct");
                      setProduct("item-modal-show");
                      setProductImg(e.image);
                      setProductId(e._id);
                    }}
                    src={e.image}
                    loading="lazy"
                  />
                  {!e.name ? (
                    ""
                  ) : e.name === "" ? (
                    ""
                  ) : (
                    <Card.Body className="p-o m-0">{e.name}</Card.Body>
                  )}
                </Card>
              ))
            ) : (
              <div style={{ color: "darkgrey" }}>
                <h4 style={{ fontFamily: "sans-serif" }}>
                  Sorry, products will update soon.
                </h4>
                <h5 style={{ fontFamily: "sans-serif" }}>
                  I’ll let you know as soon as we update products.
                </h5>
              </div>
            )}
          </div>
          <div className="text-center mt-3">
            <span className="border p-1 rounded bg-secondary text-white">
              Page {page}
            </span>
          </div>
          <div className="text-center ">
            <button
              aria-label="previous product page"
              disabled={page === 1 && true}
              style={{ height: "35px", width: "40vw" }}
              className="border rounded m-2 "
              onClick={handlepageBackward}
            >
              Previous page
            </button>
            <button
              aria-label="next product page"
              disabled={data.length < 12 && true}
              style={{ height: "35px", width: "40vw" }}
              className="border rounded mt-4 "
              onClick={handlepageForward}
            >
              Next page
            </button>
          </div>
        </div>
        <div className={`item-modal ${product}`}>
          <i
            onClick={(y) => addToFavHandler(y, productImg, productId)}
            className={
              existingFavItems?.find((item) => item.id === productId)
                ? "fa fa-heart iconproduct-fav active"
                : "fa fa-heart iconproduct-fav"
            }
            aria-hidden="true"
          ></i>{" "}
          <img
            className="img-fluid rounded popup-img"
            src={productImg}
            alt="logo"
          />
          <img
            onClick={() => {
              setProductCont("");
              setProduct("item-modalhide");
              setProductImg("");
              setProductId("");
            }}
            src={closebtn}
            alt="close"
            className="close-btn img-fluid"
          />
          <h1>
            <Button
              aria-label="order product"
              style={{ width: "100%", fontWeight: "bold" }}
              onClick={() => navigate(`/query/${productId}`)}
              variant="warning"
            >
              Order Now
            </Button>
          </h1>
        </div>
      </main>

      <footer>
        <FormSub />
        <FooterTop />
        <FooterMid />
      </footer>
      <div className={productCont} id="layout"></div>
    </>
  );
}
