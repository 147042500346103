import React from "react";

export default function Categories() {
  return window.innerWidth >= 780 ? (
    <>
      <>
        <h2 className="d-flex flex-column with-stroke text-center ">
          <span style={{ fontSize: "20px" }}>SHOP YOUR FAVOURITE</span>
          <span style={{ fontSize: "14px" }}>Top Picks For You</span>
        </h2>
        <div className="ws-scroll">
          <div className="top-list">
            <a aria-label="sofaset" href="/category/sofa-sets?page=1">
              <figure>
                <img
                  className="img-fluid"
                  src="/images/home-new1/icon1.jpg"
                  alt="Sofa set online"
                  title="Sofa set online"
                  width="100%"
                  height="100%"
                />
              </figure>
              <span className="cat-name">Sofa Sets</span>
            </a>
            <a aria-label="beds" href="/category/beds?page=1">
              <figure>
                <img
                  className="img-fluid"
                  src="/images/home-new1/icon2.jpg"
                  alt="Wooden Bed"
                  title="Wooden Bed"
                  width="100%"
                  height="100%"
                />
              </figure>
              <span className="cat-name">Beds</span>
            </a>
            <a aria-label="table" href="/category/table?page=1">
              <figure>
                {" "}
                <img
                  className="img-fluid"
                  src="/images/home-new1/icon3.jpg"
                  alt="Coffee Table"
                  title="Coffee Table"
                  width="100%"
                  height="100%"
                />
              </figure>
              <span className="cat-name">Table</span>
            </a>
            <a aria-label="recliner sofa" href="/category/recliner-sofa?page=1">
              <figure>
                <img
                  className="img-fluid"
                  src="/images/home-new1/icon4.jpg"
                  alt="Book Shelevs"
                  title="Book Shelves"
                  width="100%"
                  height="100%"
                />
              </figure>
              <span className="cat-name">Recliner Sofa</span>
            </a>
            <a aria-label="wardrobes" href="/category/wardrobes?page=1">
              <figure>
                <img
                  className="img-fluid"
                  src="/images/home-new1/icon5.png"
                  alt="Shoe Racks online"
                  title="Shoe Racks online"
                  width="100%"
                  height="100%"
                />
              </figure>
              <span className="cat-name">Wardrobes</span>
            </a>
            <a aria-label="office table" href="/category/office-table?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon6.jpg"
                  alt="Tv Unit online"
                  title="Sofa cum bed online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Office Table</span>
            </a>
            <a
              aria-label="grandfather clock"
              href="/category/grandfather-clock?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon23.png"
                  alt="Decor Item"
                  title="Decor Item"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Grandfather Clock</span>
            </a>
            <a
              aria-label="chair dining set"
              href="/category/chair-dining-set?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon24.png"
                  alt="lamp &amp; Lighting"
                  title="lamp &amp; Lighting"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Chair Dining Set</span>
            </a>
          </div>
          <div className="top-list">
            <a aria-label="book shelves" href="/category/book-shelves?page=1">
              <figure>
                {" "}
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon7.jpg"
                  alt="Study Table"
                  title="Study Table"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Book Shelves</span>
            </a>
            <a aria-label="coffee table" href="/category/coffee-table?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon8.jpg"
                  alt="Modular Kitchen online"
                  title="Modular Kitchen online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Coffee Table</span>
            </a>
            <a aria-label="carpet" href="/category/carpet?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon9.jpg"
                  alt="Decor Item"
                  title="Decor Item"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Carpet</span>
            </a>
            <a aria-label="tv stand" href="/category/tv-stand?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon10.jpg"
                  alt="lamp &amp; Lighting"
                  title="lamp &amp; Lighting"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Tv Stand</span>
            </a>
            <a aria-label="home decor" href="/category/home-decor?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon11.jpg"
                  alt="Home Furnishing"
                  title="Home Furnishing"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Home Decor</span>
            </a>
            <a
              aria-label="lamps and lights"
              href="/category/lamps-&-lighting?page=1"
            >
              <figure>
                {" "}
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon12.jpg"
                  alt="Dressing Table"
                  title="Dressing Tables"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Lamps & Lighting</span>
            </a>
            <a
              aria-label="crockery shelf"
              href="/category/crokery-shelf?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon21.png"
                  alt="Sofa cum bed online"
                  title="Sofa cum bed online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Crokery Shelf</span>
            </a>
            <a
              aria-label="bed side table"
              href="/category/bed-side-table?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon22.png"
                  alt="Modular Kitchen online"
                  title="Modular Kitchen online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Bed Side Table</span>
            </a>
          </div>
          <div className="top-list  border-bottom pb-4">
            <a aria-label="shoe rack" href="/category/shoe-rack?page=1">
              <figure>
                {" "}
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon13.jpg"
                  alt="Dining Table set"
                  title="Dining Table set"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Shoe Rack</span>
            </a>
            <a
              aria-label="dressing tables"
              href="/category/dressing-tables?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon14.jpg"
                  alt="Sofa cum bed online"
                  title="Sofa cum bed online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Dressing Tables</span>
            </a>
            <a
              aria-label="modular kitchen"
              href="/category/modular-kitchen?page=1"
            >
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon15.jpg"
                  alt="Modular Kitchen online"
                  title="Modular Kitchen online"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Modular Kitchen</span>
            </a>
            <a aria-label="stool" href="/category/stool?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon16.png"
                  alt="Decor Item"
                  title="Decor Item"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Stool</span>
            </a>
            <a aria-label="sitout chair" href="/category/sitout-chair?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon17.png"
                  alt="lamp &amp; Lighting"
                  title="lamp &amp; Lighting"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Sitout Chair</span>
            </a>
            <a aria-label="diwan" href="/category/diwan?page=1">
              <figure>
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon18.png"
                  alt="Home Furnishing"
                  title="Home Furnishing"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Diwan</span>
            </a>
            <a aria-label="bench" href="/category/bench?page=1">
              <figure>
                {" "}
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon19.png"
                  alt="Dressing Table"
                  title="Dressing Tables"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Bench</span>
            </a>
            <a aria-label="display unit" href="/category/display-unit?page=1">
              <figure>
                {" "}
                <img
                  className="img-fluid lazy lazy1 loaded"
                  src="/images/home-new1/icon20.png"
                  alt="Dining Table set"
                  title="Dining Table set"
                  width="100%"
                  height="100%"
                  data-was-processed="true"
                />
              </figure>
              <span className="cat-name">Dispaly unit</span>
            </a>
          </div>
        </div>
      </>
    </>
  ) : (
    //small screen layout
    <>
      <h2 className="d-flex flex-column with-stroke text-center ">
        <span style={{ fontSize: "20px" }}>SHOP YOUR FAVOURITE</span>
        <span style={{ fontSize: "14px" }}>Top Picks For You</span>
      </h2>
      <div className="ws-scroll">
        <div className="top-list">
          <a aria-label="Sofa Sets" href="/category/sofa-sets?page=1">
            <figure>
              <img
                className="img-fluid"
                src="/images/home-new1/icon1.jpg"
                alt="Sofa set online"
                title="Sofa set online"
                width="100%"
                height="100%"
              />
            </figure>
            <span className="cat-name">Sofa Sets</span>
          </a>
          <a aria-label="Beds" href="/category/beds?page=1">
            <figure>
              <img
                className="img-fluid"
                src="/images/home-new1/icon2.jpg"
                alt="Wooden Bed"
                title="Wooden Bed"
                width="100%"
                height="100%"
              />
            </figure>
            <span className="cat-name">Beds</span>
          </a>
          <a aria-label="Table" href="/category/table?page=1">
            <figure>
              {" "}
              <img
                className="img-fluid"
                src="/images/home-new1/icon3.jpg"
                alt="Coffee Table"
                title="Coffee Table"
                width="100%"
                height="100%"
              />
            </figure>
            <span className="cat-name">Table</span>
          </a>
          <a aria-label="Recliner Sofa" href="/category/recliner-sofa?page=1">
            <figure>
              <img
                className="img-fluid"
                src="/images/home-new1/icon4.jpg"
                alt="Book Shelevs"
                title="Book Shelves"
                width="100%"
                height="100%"
              />
            </figure>
            <span className="cat-name">Recliner Sofa</span>
          </a>
        </div>
        <div className="top-list">
          <a aria-label="Wardrobes" href="/category/wardrobes?page=1">
            <figure>
              <img
                className="img-fluid"
                src="/images/home-new1/icon5.png"
                alt="Shoe Racks online"
                title="Shoe Racks online"
                width="100%"
                height="100%"
              />
            </figure>
            <span className="cat-name">Wardrobes</span>
          </a>
          <a aria-label="Office Table" href="/category/office-table?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon6.jpg"
                alt="Tv Unit online"
                title="Sofa cum bed online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Office Table</span>
          </a>
          <a aria-label="Book Shelves" href="/category/book-shelves?page=1">
            <figure>
              {" "}
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon7.jpg"
                alt="Study Table"
                title="Study Table"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Book Shelves</span>
          </a>
          <a aria-label="Coffee Table" href="/category/coffee-table?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon8.jpg"
                alt="Modular Kitchen online"
                title="Modular Kitchen online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Coffee Table</span>
          </a>
        </div>
        <div className="top-list">
          <a aria-label="Carpet" href="/category/carpet?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon9.jpg"
                alt="Decor Item"
                title="Decor Item"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Carpet</span>
          </a>
          <a aria-label="Tv Stand" href="/category/tv-stand?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon10.jpg"
                alt="lamp &amp; Lighting"
                title="lamp &amp; Lighting"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Tv Stand</span>
          </a>
          <a aria-label="Home Decor" href="/category/home-decor?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon11.jpg"
                alt="Home Furnishing"
                title="Home Furnishing"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Home Decor</span>
          </a>
          <a
            aria-label="Lamps & Lighting"
            href="/category/lamps-&-lighting?page=1"
          >
            <figure>
              {" "}
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon12.jpg"
                alt="Dressing Table"
                title="Dressing Tables"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Lamps & Lighting</span>
          </a>
        </div>
        <div className="top-list  ">
          <a aria-label="Shoe Rack" href="/category/shoe-rack?page=1">
            <figure>
              {" "}
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon13.jpg"
                alt="Dining Table set"
                title="Dining Table set"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Shoe Rack</span>
          </a>
          <a
            aria-label="Dressing Tables"
            href="/category/dressing-tables?page=1"
          >
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon14.jpg"
                alt="Sofa cum bed online"
                title="Sofa cum bed online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Dressing Tables</span>
          </a>
          <a
            aria-label="Modular Kitchen"
            href="/category/modular-kitchen?page=1"
          >
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon15.jpg"
                alt="Modular Kitchen online"
                title="Modular Kitchen online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Modular Kitchen</span>
          </a>
          <a aria-label="Stool" href="/category/stool?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon16.png"
                alt="Decor Item"
                title="Decor Item"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Stool</span>
          </a>
        </div>
        <div className="top-list">
          <a aria-label="Sitout Chair" href="/category/sitout-chair?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon17.png"
                alt="lamp &amp; Lighting"
                title="lamp &amp; Lighting"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Sitout Chair</span>
          </a>
          <a aria-label="Diwan" href="/category/diwan?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon18.png"
                alt="Home Furnishing"
                title="Home Furnishing"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Diwan</span>
          </a>
          <a aria-label="Bench" href="/category/bench?page=1">
            <figure>
              {" "}
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon19.png"
                alt="Dressing Table"
                title="Dressing Tables"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Bench</span>
          </a>
          <a aria-label="Dispaly unit" href="/category/display-unit?page=1">
            <figure>
              {" "}
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon20.png"
                alt="Dining Table set"
                title="Dining Table set"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Dispaly unit</span>
          </a>
        </div>
        <div div className="top-list border-bottom pb-4">
          <a aria-label="Crokery Shelf" href="/category/crokery-shelf?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon21.png"
                alt="Sofa cum bed online"
                title="Sofa cum bed online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Crokery Shelf</span>
          </a>
          <a aria-label="Bed Side Table" href="/category/bed-side-table?page=1">
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon22.png"
                alt="Modular Kitchen online"
                title="Modular Kitchen online"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Bed Side Table</span>
          </a>
          <a
            aria-label="Grandfather Clock"
            href="/category/grandfather-clock?page=1"
          >
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon23.png"
                alt="Decor Item"
                title="Decor Item"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Grandfather Clock</span>
          </a>
          <a
            aria-label="Chair Dining Set"
            href="/category/chair-dining-set?page=1"
          >
            <figure>
              <img
                className="img-fluid lazy lazy1 loaded"
                src="/images/home-new1/icon24.png"
                alt="lamp &amp; Lighting"
                title="lamp &amp; Lighting"
                width="100%"
                height="100%"
                data-was-processed="true"
              />
            </figure>
            <span className="cat-name">Chair Dining Set</span>
          </a>
        </div>
      </div>
    </>
  );
}
