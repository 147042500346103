import axios from "axios";
import { apiStart } from "../../apiurl/apiData";

export const loginAdmin = (file) => async (dispatch) => {
  try {
    dispatch({ type: "loginAdminRequest" });
    console.log(file);
    const { data } = await axios.post(`${apiStart}/api/login/`, file, {
      withCredentials: true,
    });
    dispatch({
      type: "loginAdminSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "loginAdminFailure",
      payload: error.message,
    });
  }
};

export const logoutAdmin = (file) => async (dispatch) => {
  try {
    dispatch({
      type: "logoutAdminRequest",
    });

    const { data } = await axios.post(`${apiStart}/api/login/logout`, {
      withCredentials: true,
    });

    dispatch({
      type: "loginAdminSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "loginAdminFailure",
      payload: error.message,
    });
  }
};

export const loadAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "loadAdminRequest",
    });
    const { data } = await axios.get(`${apiStart}/api/login/load`, {
      withCredentials: true,
    });

    dispatch({
      type: "loadAdminSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "loadAdminFailure",
      payload: error.message,
    });
  }
};
