export const addtofavourite = (file) => async (dispatch) => {
  try {
    const newItem = file;
    const existingFavItems =
      JSON.parse(localStorage.getItem("favItems")) == null
        ? []
        : JSON.parse(localStorage.getItem("favItems"));

    const existItem = existingFavItems?.find((item) => item.id === newItem.id);
    console.log(existItem);
    const favItems = existItem
      ? existingFavItems.map((item) =>
          item.id === existItem.id ? newItem : item
        )
      : [...existingFavItems, newItem];

    localStorage.setItem("favItems", JSON.stringify(favItems));
    dispatch({
      type: "addedtofavourite",
    });
  } catch (error) {
    dispatch({
      type: "notaddedtofavourite",
      payload: "Not added to favorite",
    });
  }
};

export const removeFromfavourite = (file) => async (dispatch) => {
  try {
    const existingFavItems = JSON.parse(localStorage.getItem("favItems"));
    const favItems = existingFavItems.filter((item) => item.id !== file.id);
    localStorage.setItem("favItems", JSON.stringify(favItems));
    dispatch({
      type: "removefromfavourite",
    });
  } catch (error) {
    dispatch({
      type: "notremovefromfavourite",
      payload: "Not Removed from favourite",
    });
  }
};
