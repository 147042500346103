import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";

const NewType = ({ inputs, title }) => {
  const navigate = useNavigate();
  const { type } = useParams();

  const [file, setFile] = useState("");
  const [text, setText] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("image", file);
    formData.append("text", text);
    await axios.post(`${apiStart}/api/${type}/`, formData, {
      withCredentials: true,
    });
    window.location.href = `/admin/${type}`;
  };

  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="newContainer">
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt="imgs"
                className="img-fluid"
              />
            </div>
            <div className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="formInput">
                  <Form.Label htmlFor="file">
                    Image upload:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </Form.Label>
                  <Form.Control
                    id="file"
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    type="text"
                    name="text"
                    placeholder="Enter product name"
                  />
                </Form.Group>

                <Button aria-label="add new product" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewType;
