import React, { useState } from "react";
import "./searchScreen.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import HeaderTop from "../../components/HeaderTop";
import HeaderMid from "../../components/HeaderMid";
import HeaderLow from "../../components/HeaderLow";
import MobileHead from "../../components/MobileHead";
import FormSub from "../../components/FormSub";
import FooterTop from "../../components/FooterTop";
import FooterMid from "../../components/FooterMid";
import Helmet from "react-helmet";
import closebtn from "./closebtn.png";
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/Loader";
import { apiStart } from "../../apiurl/apiData";

export default function SearchScreen() {
  const params = useParams();
  const { query } = params;
  const navigate = useNavigate();

  const [product, setProduct] = useState("hide");
  const [productCont, setProductCont] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productId, setProductId] = useState("");

  const { data, loading } = useFetch(`${apiStart}/api/products/`);
  const item = data.filter((value) =>
    value.category.toLowerCase().includes(query.toLowerCase())
  );
  console.log(item);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>search page</title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="preload" as="style" href="./searchScreen.css" />
      </Helmet>
      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>

      <main>
        <div className={productCont}>
          <div>
            <h5 className="with-stroke text-center">
              <span className="stroke-head">
                search results of..
                <span style={{ fontSize: "30px", color: "brown" }}>
                  {query.replaceAll("-", " ").charAt(0).toUpperCase() +
                    query.replaceAll("-", " ").slice(1)}
                </span>{" "}
              </span>
            </h5>
          </div>

          <div className="supreme productItem">
            {loading ? (
              <Loader />
            ) : item.length !== 0 ? (
              item.map((e) => (
                <Card>
                  <Card.Img
                    className="img-fluid lazy"
                    data-src="image-to-lazy-load-1x.jpg"
                    data-srcset="image-to-lazy-load-2x.jpg 2x, image-to-lazy-load-1x.jpg 1x"
                    alt="I'm an image!"
                    onClick={() => {
                      setProductCont("product-all");
                      setProduct("product-details");
                      setProductImg(e.image);
                      setProductId(e._id);
                    }}
                    src={e.image}
                  />
                </Card>
              ))
            ) : (
              <div style={{ color: "darkgrey" }}>
                <h4 style={{ fontFamily: "sans-serif" }}>
                  Sorry, no matches were found.
                </h4>
                <h5 style={{ fontFamily: "sans-serif" }}>Try a new search.</h5>
              </div>
            )}
          </div>
        </div>
        <div className={product}>
          <img
            className="img-fluid rounded popup-img"
            src={productImg}
            alt="logo"
          />
          <img
            onClick={() => {
              setProductCont("");
              setProduct("hide");
              setProductImg("");
              setProductId("");
            }}
            src={closebtn}
            alt="close"
            className="close-btn img-fluid"
          />
          <h1>
            <Button
              aria-label="order product"
              style={{ width: "100%", fontWeight: "bold" }}
              onClick={() => navigate(`/query/${productId}`)}
              variant="warning"
            >
              Order Now
            </Button>
          </h1>
        </div>
      </main>

      <footer>
        <FormSub />
        <FooterTop />
        <FooterMid />
      </footer>
    </>
  );
}
