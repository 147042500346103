import Slide from './components/Slide';
import { Container, Col, Row } from 'react-bootstrap';
import Categories from './components/Categories';
import BestSellers from './components/BestSellers';
import NewLauches from './components/NewLauches';
import 'react-toastify/dist/ReactToastify.css';
import HeaderTop from '../../components/HeaderTop';
import HeaderMid from '../../components/HeaderMid';
import HeaderLow from '../../components/HeaderLow';
import MobileHead from '../../components/MobileHead';
import FormSub from '../../components/FormSub';
import FooterTop from '../../components/FooterTop';
import FooterMid from '../../components/FooterMid';
import { Helmet } from 'react-helmet';
import Social from './components/Social';
import CustomerStories from './components/CustomerStories';
import './HomeScreen.css';

export default function HomeScreen() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aone Furniture & Best Deals </title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="preload" as="style" href="./HomeScreen.css" />
      </Helmet>

      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>

      <main className="mt-3">
        <Slide />
        <Container fluid>
          <Col>
            <Row>
              <Categories />
            </Row>
            <Row className="supreme-home">
              <NewLauches />
              <hr />
              <div className="d-flex justify-content-center">
                <img
                  className="ad-img img-fluid"
                  src="/images/ads.png"
                  alt="advertisements"
                  loading="lazy"
                />
              </div>
              <hr />
              <BestSellers data="Best Sellers" />
            </Row>
          </Col>
        </Container>
      </main>
      <footer>
        <FormSub />
        <CustomerStories />
        <div>
          <FooterTop />
          <FooterMid />
          <Social />
        </div>
      </footer>
    </>
  );
}
