export const subscribeUser = () => async (dispatch) => {
  try {
    localStorage.setItem("subscribe", JSON.stringify("true"));

    dispatch({
      type: "subscribed",
    });
  } catch (error) {
    dispatch({
      type: "notsubscribed",
      payload: error.response.data.message,
    });
  }
};
