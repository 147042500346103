import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { subscribeUser } from "../store/Actions/Subscribe";
import { useDispatch } from "react-redux";
import { apiStart } from "../apiurl/apiData";

export default function FormSub() {
  const dispatch = useDispatch();
  const notify = () => toast(`SUBSCRIBED....!`);

  const [credentials, setCredentials] = useState({
    number: undefined,
  });
  const [feed, setFeed] = useState("");
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setCredentials({ number: parseInt(e.target.value) });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${apiStart}/api/subscribe/`, credentials, {
        withCredentials: true,
      });
      if (res.data) {
        notify();
        dispatch(subscribeUser());
      } else {
        console.log("failed");
      }
    } catch (err) {
      console.log("mot worked");
    }
  };

  const changeFeed = { feedback: feed };
  console.log(feed);
  const handleFeedback = async (e) => {
    e.preventDefault();
    try {
      const data = await axios.post(`${apiStart}/api/feed/`, changeFeed);
      setFeed("");
      setStatus(data.data.message);
    } catch (err) {
      console.log("not worked");
    }
  };
  const subscribe = JSON.parse(localStorage.getItem("subscribe"));
  return (
    <>
      <div className="text-center m-3 border-bottom">
        {subscribe === (false || null) ? (
          <>
            <span className="stroke-head">SUBSCRIBE AND REACHOUT SOON</span>
            <div className="stroke-body">
              <div className="mb-3 stroke-body1 d-flex">
                <Form.Control
                  type="number"
                  placeholder="Mobile Number"
                  id="number"
                  onChange={handleChange}
                  className="lInput"
                  required
                />
                <Button
                  style={{ marginLeft: "5px" }}
                  aria-label="subscribe"
                  variant="warning"
                  onClick={handleClick}
                  className="lButton"
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <span className="stroke-head">Customer Feedback</span>
            <div className="stroke-body">
              <div className="stroke-body1 d-flex">
                <Form.Control
                  as="textarea"
                  value={feed}
                  placeholder="Enter your feedback..."
                  onChange={(e) => setFeed(e.target.value)}
                  className="lInput "
                  required
                />
                <Button
                  style={{ marginLeft: "5px" }}
                  variant="warning"
                  aria-label="feedback"
                  onClick={handleFeedback}
                  className="lButton border light "
                >
                  Submit
                </Button>
              </div>
            </div>
            <div style={{ color: "orange" }} className="mb-3">
              {status}
            </div>
          </>
        )}
      </div>
    </>
  );
}
