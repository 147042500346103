import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Rating from "../../../components/Rating";
import useFetch from "../../../hooks/useFetch";
import { apiStart } from "../../../apiurl/apiData";

export default function CustomerStories() {
  const { data } = useFetch(`${apiStart}/api/feed/`);

  const [hide, setHide] = useState(true);
  return (
    <div className="border-bottom">
      <h2 className="with-stroke text-center ">
        <span className="stroke-head">Customer Stories</span>
      </h2>
      <div key="1" className="review-cont">
        <div key="11" className="review-each border rounded">
          <img
            style={{
              borderRadius: "240px",
              width: "90px",
              height: "90px",
            }}
            className="p-3"
            src="/images/review/rev1.jpg"
            alt="rev1"
          />
          <h4 style={{ fontFamily: "sans-serif" }}>Suhail Ma</h4>
          <Rating rating="5" />
          <p style={{ color: "#F9913D" }}>Ernakulam,kerala</p>
          <p style={{ color: "grey" }}>
            "We purchased so many products from Aone and we would recommend Aone
            Furniture to everyone who needs quality and decent looking
            furniture.
          </p>
        </div>
        <div key="12" className="review-each border rounded">
          <img
            style={{
              borderRadius: "240px",
              width: "90px",
              height: "90px",
            }}
            className="p-3"
            src="/images/review/rev2.jpg"
            alt="rev1"
          />
          <h4 style={{ fontFamily: "sans-serif" }}>Manaf km</h4>
          <Rating rating="5" />
          <p style={{ color: "#F9913D" }}>Kochi,kerala</p>
          <p style={{ color: "grey" }}>
            "We have been purchasing furniture from Aone since the last 3 years.
            We appreciate their prompt service and so far we have faced no
            complaints with their furniture.
          </p>
        </div>
        <div key="13" className="review-each border rounded">
          <img
            style={{
              borderRadius: "240px",
              width: "90px",
              height: "90px",
            }}
            className="p-3"
            src="/images/review/rev3.jpg"
            alt="rev1"
          />
          <h4 style={{ fontFamily: "sans-serif" }}>Niyas km</h4>
          <Rating rating="4.5" />
          <p style={{ color: "#F9913D" }}>Aluva,kerala</p>

          <p style={{ color: "grey" }}>
            "Good product and timely delivery. Satisfied with showroom visit,
          </p>
        </div>
      </div>
      <div key="2">
        <div key="21" style={{ margin: "30px 0px " }} className="text-center">
          <Button
            aria-label="view all customer stories"
            onClick={() => setHide(!hide)}
            className="allstories"
          >
            View all customer stories
          </Button>
        </div>
        <div key="22" className={hide ? "hide " : "setstories"}>
          <div>
            {data
              .slice(0)
              .reverse()
              .map((e) => (
                <div
                  className="testitext "
                  itemProp="reviewBody"
                  style={{ wordBreak: "break-word" }}
                >
                  <span style={{ visibility: "hidden" }}>sdfd</span>
                  {e.feedback}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
