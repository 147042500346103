import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isAdmin: false,
  error: null,
  message: null,
};

export const adminReducer = createReducer(initialState, {
  loginAdminRequest: (state) => {
    state.loading = true;
    state.isAdmin = false;
  },
  loginAdminSuccess: (state, action) => {
    state.loading = false;
    state.isAdmin = true;
    state.message = action.payload;
  },
  loginAdminFailure: (state, action) => {
    state.loading = false;
    state.isAdmin = false;
    state.error = action.payload;
  },
  logoutAdminRequest: (state) => {
    state.loading = true;
  },
  logoutAdminSuccess: (state, action) => {
    state.loading = false;
    state.isAdmin = false;
    state.message = action.payload;
  },
  logoutAdminFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  loadAdminRequest: (state) => {
    state.loading = true;
    state.isAdmin = false;
  },
  loadAdminSuccess: (state, action) => {
    state.loading = false;
    state.isAdmin = true;
    state.message = action.payload;
  },
  loadAdminFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAdmin = false;
  },
  clearErrors: (state, action) => {
    state.loading = false;
    state.error = "";
    state.message = "";
  },
});
