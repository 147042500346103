import "./SubscribersScreen.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiStart } from "../../../apiurl/apiData";

export default function SubscribersScreen() {
  const navigate = useNavigate();

  const { data } = useFetch(`${apiStart}/api/subscribe`);

  const handleDelete = async (e) => {
    navigate("/admin");
    await axios.delete(`${apiStart}/api/subscribe/${e}`, {
      withCredentials: true,
    });
    navigate("/admin/subscriptions");
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>SUBSCRIBERS</h1>
        </div>
        <div className="main-set">
          <ol>
            {data?.map((e, i) => (
              <li key={i} className="d-flex justify-content-between">
                <span>
                  {i + 1}) {e.number}
                </span>
                <span
                  style={{ color: "white" }}
                  className="bg-danger p-1 m-1 border rounded"
                  onClick={() => handleDelete(e._id)}
                >
                  delete
                </span>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
