import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSub from "../../components/FormSub";
import FooterMid from "../../components/FooterMid";
import HeaderTop from "../../components/HeaderTop";
import "./query.css";
import Helmet from "react-helmet";
import { Form, Button } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import $ from "jquery";
import { apiStart } from "../../apiurl/apiData";

export default function QueryScreen() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [address1, setAddress1] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");

  const params = useParams();
  const { id } = params;
  const { data } = useFetch(`${apiStart}/api/products/${id}`);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
  const allDataInput = {
    name: name,
    email: email,
    number: parseInt(number),
    pincode: parseInt(postalcode),
    address1: address1,
    address2: city,
    address3: landmark,
    image: data.image,
  };

  const handleClick = async (e) => {
    e.preventDefault();
    $("#orderSubmit").html("Loading...");
    $("#orderSubmit").prop("disabled", true);
    try {
      localStorage.setItem("address", JSON.stringify(allDataInput));
      const res = await axios.post(`${apiStart}/api/order/`, allDataInput, {
        withCredentials: true,
      });
      if (res.data) {
        navigate("/query/success");
      } else {
        console.log("failed");
      }
    } catch (err) {
      console.log("mot worked");
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Query page</title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="preload" as="style" href="./query.css" />
      </Helmet>
      {window.innerWidth >= 830 ? (
        <header className="App-header border-bottom">
          <HeaderTop />
        </header>
      ) : (
        ""
      )}
      <main>
        <div className="mainquery pt-5 pb-5" style={{ background: "#f9f8f2" }}>
          <div
            style={{ background: "white" }}
            className="formquery container border p-3"
          >
            <h4 className="border-bottom">Delivery & Billing Address</h4>
            <Form className="row g-3">
              <Form.Group>
                <Form.Label>
                  Full Name{" "}
                  {name === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Email address{" "}
                  {email === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Contact Number{" "}
                  {number === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="+91"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Postal Code{" "}
                  {postalcode === "" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  type="number"
                  value={postalcode}
                  onChange={(e) => setPostalcode(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Address{" "}
                  {address1 === "" || city === "" || landmark === "" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  placeholder="Address line 1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="Landmark"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <div className="mb-3">
                  <Button
                    id="orderSubmit"
                    aria-label="address submit"
                    style={{ width: "100%", fontWeight: "bold" }}
                    type="submit"
                    className="btn btn-warning"
                    variant="primary"
                    onClick={handleClick}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </div>

          <div
            style={{ background: "white" }}
            className="container border mt-5 p-3"
          >
            <h4 className="border-bottom">order summary</h4>
            <div className="spec-data">
              <img
                style={{ width: "740px" }}
                className="img-fluid"
                src={data.image}
                alt="order"
              />
              {data.name && (
                <h1
                  className="bg-success text-center"
                  style={{ width: "100%", color: "white" }}
                >
                  {data.name}
                </h1>
              )}
            </div>
          </div>
        </div>
      </main>
      <footer>
        <FormSub />
        <FooterMid />
      </footer>
    </>
  );
}
