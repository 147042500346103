import "./edit.scss";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Sidebar from "../../../components/sidebar/Sidebar";
import useFetch from "../../../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";

const EditProduct = () => {
  const categoryoptions = [
    "sofa-sets",
    "beds",
    "table",
    "recliner-sofa",
    "wardrobes",
    "office-table",
    "grandfather-clock",
    "chair-dining-set",
    "book-shelves",
    "coffee-table",
    "carpet",
    "tv-stand",
    "home-decor",
    "lamps-&-lighting",
    "crokery-shelf",
    "bed-side-table",
    "shoe-rack",
    "dressing-tables",
    "modular-kitchen",
    "stool",
    "sitout-chair",
    "diwan",
    "bench",
    "dispaly-unit",
  ];
  const subCategoryoptions = [
    "living",
    "bedroom",
    "dining",
    "storage",
    "kidsroom",
    "decor",
    "furnishing",
    "outdoor",
  ];
  const navigate = useNavigate();

  const { id } = useParams();
  const { data, loading, error } = useFetch(`${apiStart}/api/products/${id}`);

  const [file, setFile] = useState("");
  const [preview, setPreview] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [name, setName] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  console.log(data);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file !== "") {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("category", category === "" ? data.category : category);
      formData.append(
        "subcategory",
        subCategory === "" ? data.subcategory : subCategory
      );
      formData.append("name", name === "" ? data.name : name);
      await axios.put(`${apiStart}/api/products/file/${id}`, formData, {
        withCredentials: true,
      });
    } else {
      await axios.put(
        `${apiStart}/api/products/${id}`,
        {
          category: category === "" ? data.category : category,
          subcategory: subCategory === "" ? data.subcategory : subCategory,
          name: name === "" ? data.name : name,
        },
        {
          withCredentials: true,
        }
      );
    }
    window.location.href = `/admin/products`;
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>Edit Product</h1>
        </div>
        <div className="main-set">
          <div className="text-center">
            <img
              className="img-fluid"
              src={file !== "" ? preview : data.image}
              alt="products"
            />
          </div>

          <div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="formInput">
                <Form.Label htmlFor="file">
                  Image upload:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </Form.Label>
                <Form.Control
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Category:</Form.Label>
                <Form.Select onChange={(e) => setCategory(e.target.value)}>
                  <option selected>{data.category}</option>
                  {categoryoptions
                    .filter((e) => e !== data.category)
                    .map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Sub Category:</Form.Label>
                <Form.Select
                  defaultValue={data.subcategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value={data.subcategory} selected>
                    {data.subcategory}
                  </option>
                  {subCategoryoptions
                    .filter((e) => e !== data.subcategory)
                    .map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={data.name}
                    type="text"
                    name="text"
                    placeholder="Enter product name"
                  />
                </Form.Group>
              </>
              {category === "" &&
              file === "" &&
              subCategory === "" &&
              name === "" ? (
                <Button aria-label="edit product" disabled type="submit">
                  Submit
                </Button>
              ) : (
                <Button type="submit">Submit</Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
