import "./Chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useFetch from "../../../hooks/useFetch";
import { apiStart } from "../../../apiurl/apiData";

const Chart = ({ aspect, title }) => {
  const { data } = useFetch(`${apiStart}/api/order/`);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  const ff = data.filter((e) => e.createdAt.split("-")[0] == yyyy);
  const gg = ff.filter((e) => e.createdAt.split("-")[1] == mm).length;

  const datas = [
    {
      name: "January",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 1).length,
    },
    {
      name: "February",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 2).length,
    },
    {
      name: "March",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 3).length,
    },
    {
      name: "April",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 4).length,
    },
    {
      name: "May",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 5).length,
    },
    {
      name: "June",
      Total: ff.filter((e) => e.createdAt.split("-")[1] == 6).length,
    },
  ];
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={datas}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
