import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../../store/Actions/Admin";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [emial, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const { admin } = useSelector((state) => state);
  const credentials = {
    email: emial,
    password: password,
  };
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch(loginAdmin(credentials));
  };
  useEffect(() => {
    dispatch({
      type: "clearErrors",
    });
  });
  return (
    <div className="login">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login page</title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <h1 className="stroke-head mb-5">Login to Admin panel</h1>
      <Container className="border rounded p-4">
        <Form.Group className="mb-3 " controlId="formBasicEmail">
          <Form.Label>Email id</Form.Label>
          <Form.Control
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
          />
        </Form.Group>
        <div style={{ color: "darkred" }}>{admin?.error}</div>
        <Button
          aria-label="login"
          onClick={handleClick}
          variant="primary"
          type="submit"
        >
          Login
        </Button>
      </Container>
    </div>
  );
};

export default LoginScreen;
