import { Col, Container, Row } from "react-bootstrap";
import "./admin.scss";
import Helmet from "react-helmet";
import Sidebar from "./components/sidebar/Sidebar";
import Widget from "./components/widget/Widget";
import Chart from "./components/chart/Chart";

export default function AdminScreen() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin page</title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>

      <Container fluid className="home">
        <Row>
          <Col md={3}>
            <Sidebar />
          </Col>
          <Col md={9} className="homeContainer">
            <div className="widgets">
              <Widget type="Products" />
              <Widget type="bestseller" />
              <Widget type="newlaunch" />
              <Widget type="order" />
              <Widget type="subscription" />
            </div>
            <div>
              <Chart title="Last 6 Months (Orders)" aspect={2 / 1} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
