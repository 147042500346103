import React, { useState } from "react";
import { Button, Container, Form, InputGroup, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default function HeaderMid() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleSearch = () => {
    navigate(`/search/${search}`);
  };
  const existingFavItems = JSON.parse(localStorage.getItem("favItems"));

  return (
    <Container>
      <Nav
        className="head-mid"
        activeKey="/"
        onSelect={(selectedKey) => `selected ${selectedKey}`}
      >
        <Nav.Item>
          <Nav.Link aria-label="home" href="/" className="contact-us ">
            <img className="logomid" src="/logo.png" alt="logo" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="contact-us">
            <InputGroup className="mb-3 formwidth">
              <Form.Control
                className="border border-secondary border-end-0"
                placeholder="Search Product and more ..."
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                aria-label="search product"
                className="border border-secondary border-start-0"
                variant="outline-secondary"
                id="button-addon2"
                onClick={handleSearch}
              >
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="18.762"
                  height="20"
                  viewBox="0 0 18.762 20"
                >
                  <path
                    d="M19.461,18.217l-4.625-4.951a8.191,8.191,0,0,0,1.841-5.191A7.972,7.972,0,0,0,8.83,0,7.972,7.972,0,0,0,.984,8.075,7.972,7.972,0,0,0,8.83,16.15a7.611,7.611,0,0,0,4.5-1.461l4.66,4.988a1,1,0,0,0,1.447.029A1.076,1.076,0,0,0,19.461,18.217ZM8.83,2.107a5.892,5.892,0,0,1,5.8,5.968,5.892,5.892,0,0,1-5.8,5.968,5.892,5.892,0,0,1-5.8-5.968A5.892,5.892,0,0,1,8.83,2.107Z"
                    transform="translate(-0.984)"
                    fill="#4a4a4a"
                  ></path>
                </svg>
              </Button>
            </InputGroup>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-flex">
          <Nav.Link
            aria-label="contactus"
            href="/"
            className="contact-us store icons"
          >
            <i>
              <svg
                width="28"
                height="28"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 13.0864V19.5C4.5 19.6989 4.57902 19.8897 4.71967 20.0303C4.86032 20.171 5.05109 20.25 5.25 20.25H18.75C18.9489 20.25 19.1397 20.171 19.2803 20.0303C19.421 19.8897 19.5 19.6989 19.5 19.5V13.0865"
                  stroke="#e57200"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M5.06573 3.75H18.9343C19.0973 3.75 19.2558 3.80309 19.3859 3.90124C19.516 3.99939 19.6106 4.13725 19.6554 4.29396L21 9H3L4.34458 4.29396C4.38936 4.13725 4.48396 3.99939 4.61408 3.90124C4.7442 3.80309 4.90274 3.75 5.06573 3.75Z"
                  stroke="#e57200"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M9 9V10.5C9 11.2956 8.68393 12.0587 8.12132 12.6213C7.55871 13.1839 6.79565 13.5 6 13.5C5.20435 13.5 4.44129 13.1839 3.87868 12.6213C3.31607 12.0587 3 11.2956 3 10.5V9"
                  stroke="#e57200"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M15 9V10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5V9"
                  stroke="#e57200"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M21 9V10.5C21 11.2956 20.6839 12.0587 20.1213 12.6213C19.5587 13.1839 18.7956 13.5 18 13.5C17.2044 13.5 16.4413 13.1839 15.8787 12.6213C15.3161 12.0587 15 11.2956 15 10.5V9"
                  stroke="#e57200"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </i>
            Stores
          </Nav.Link>

          <Nav.Link
            aria-label="wishlist"
            href="/wishlist"
            className="contact-us whislist-cart icons"
          >
            <i>
              <svg width="28" height="28" viewBox="0 0 26 26" fill="none">
                <g transform="translate(0 -5.713)">
                  <path
                    d="M21.964,7.925c-.321-3.535-2.823-6.1-5.954-6.1a5.918,5.918,0,0,0-5.07,2.922A5.7,5.7,0,0,0,5.99,1.826C2.86,1.826.358,4.39.037,7.925a6.289,6.289,0,0,0,.187,2.318A9.942,9.942,0,0,0,3.27,15.326l7.665,6.955,7.8-6.955a9.943,9.943,0,0,0,3.046-5.083,6.3,6.3,0,0,0,.187-2.317Zm-1.011,2.124A9.092,9.092,0,0,1,18.164,14.7l-7.225,6.446L3.839,14.7a9.1,9.1,0,0,1-2.791-4.65A5.5,5.5,0,0,1,.872,8.06l.006-.043C1.153,4.92,3.3,2.672,5.99,2.672a4.96,4.96,0,0,1,4.557,3.18l.39.924.39-.924A5.112,5.112,0,0,1,16.01,2.673c2.687,0,4.837,2.248,5.118,5.385A5.489,5.489,0,0,1,20.953,10.049Z"
                    transform="translate(0 3.887)"
                    fill="#4a4a4a"
                  ></path>
                </g>
              </svg>
            </i>
            Wishlist ({existingFavItems && existingFavItems.length})
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Container>
  );
}
