export const productsInputs = [
  {
    id: 1,
    category: ["fewe", "few"],
  },
  {
    id: 2,
    subCategory: ["fewe", "few"],
  },
];

export const typeListInputs = [
  {
    id: 1,
    label: "Product Name",
    type: "text",
    placeholder: "Enter product name",
  },
];
