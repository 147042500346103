import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "./Screens/homescreen/HomeScreen";
import About from "./Screens/AboutScreen/About";
import Help from "./Screens/AboutScreen/Help";
import FavouriteScreen from "./Screens/Favourite/FavouriteScreen";
import ProductScreen from "./Screens/productscreen/ProductScreen";
import OrderSuccess from "./Screens/QueryScreen/OrderSuccess";
import QueryScreen from "./Screens/QueryScreen/QueryScreen";
import LoginScreen from "./Admin/screens/loginScreen/LoginScreen";
import AdminScreen from "./Admin/AdminScreen";
import OrderScreen from "./Admin/screens/orderScreen/OrderScreen";
import TypeListScreen from "./Admin/screens/typeListScreen/TypeListScreen";
import { typeListInputs } from "./formSource";
import SubProductScreen from "./Screens/productscreen/SubProductScreen";
import QueryBstSeller from "./Screens/QueryScreen/QueryBstSeller";
import QueryNewLaunch from "./Screens/QueryScreen/QueryNewLaunch";
import ProductListScreen from "./Admin/screens/ProductScreen/ProductListScreen";
import EditProduct from "./Admin/screens/ProductScreen/Edit/EditProduct";
import EditType from "./Admin/screens/typeListScreen/Edit/EditType";
import NewProduct from "./Admin/screens/ProductScreen/New/NewProduct";
import NewType from "./Admin/screens/typeListScreen/New/NewType";
import SubscribersScreen from "./Admin/screens/subscriberScreen/SubscribersScreen";
import View from "./Admin/screens/orderScreen/View/View";
import SearchScreen from "./Screens/SearchScreen/SearchScreen";
import FeedbackScreen from "./Admin/screens/feedbackScreen/FeedbackScreen";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAdmin } from "./store/Actions/Admin";
import ReviewPage from "./Screens/Review/ReviewPage";

function App() {
  const dispatch = useDispatch();

  const { admin } = useSelector((state) => state);
  console.log(admin);
  useEffect(() => {
    dispatch(loadAdmin());
  }, [dispatch]);
  return (
    <Routes>
      {/* Client screen routes */}

      <Route path="/" element={<HomeScreen />}></Route>
      <Route path="/about-us" element={<About />}></Route>
      <Route path="/help" element={<Help />}></Route>
      <Route path="/wishlist" element={<FavouriteScreen />}></Route>
      <Route path="/category/:slug" element={<ProductScreen />}></Route>
      <Route path="/subCategory/:slug" element={<SubProductScreen />}></Route>
      <Route path="/query/:id" element={<QueryScreen />}></Route>
      <Route path="/query/bestseller/:id" element={<QueryBstSeller />}></Route>
      <Route path="/query/newlaunch/:id" element={<QueryNewLaunch />}></Route>
      <Route path="/query/success" element={<OrderSuccess />}></Route>
      <Route path="/search/:query" element={<SearchScreen />}></Route>
      <Route path="/review" element={<ReviewPage />}></Route>
      {/* Admin screen Routes */}

      <Route path="/login" element={<LoginScreen />}></Route>
      <Route path="/admin">
        <Route
          index
          element={admin.isAdmin ? <AdminScreen /> : <LoginScreen />}
        />
        <Route path="orders">
          <Route
            index
            element={admin.isAdmin ? <OrderScreen /> : <LoginScreen />}
          />
          <Route
            path=":id"
            element={admin.isAdmin ? <View /> : <LoginScreen />}
          ></Route>
        </Route>
        <Route
          path="subscriptions"
          element={admin.isAdmin ? <SubscribersScreen /> : <LoginScreen />}
        ></Route>
        <Route
          path="feedback"
          element={admin.isAdmin ? <FeedbackScreen /> : <LoginScreen />}
        ></Route>
        <Route path="products">
          <Route
            index
            element={admin.isAdmin ? <ProductListScreen /> : <LoginScreen />}
          />
          <Route
            path=":id"
            element={admin.isAdmin ? <EditProduct /> : <LoginScreen />}
          ></Route>
          <Route
            path="new"
            element={admin.isAdmin ? <NewProduct /> : <LoginScreen />}
          ></Route>
        </Route>
        <Route path=":type">
          <Route
            index
            element={admin.isAdmin ? <TypeListScreen /> : <LoginScreen />}
          />
          <Route
            path=":id"
            element={admin.isAdmin ? <EditType /> : <LoginScreen />}
          ></Route>
          <Route
            path="new"
            element={
              admin.isAdmin ? (
                <NewType inputs={typeListInputs} title="New Product" />
              ) : (
                <LoginScreen />
              )
            }
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
