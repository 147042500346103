import React from 'react';
import { Container } from 'react-bootstrap';
import Helmet from 'react-helmet';
import FooterMid from '../../components/FooterMid';
import FooterTop from '../../components/FooterTop';
import FormSub from '../../components/FormSub';
import HeaderLow from '../../components/HeaderLow';
import HeaderMid from '../../components/HeaderMid';
import HeaderTop from '../../components/HeaderTop';
import MobileHead from '../../components/MobileHead';
import BestSellers from '../homescreen/components/BestSellers';
import CustomerStories from '../homescreen/components/CustomerStories';
import './AbouScreen.scss';

export default function About() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About page</title>
        <meta name="description" content="Get all furniture in kerala" />
        <link rel="preload" as="style" href="./AboutScreen.scss" />
      </Helmet>
      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>
      <main>
        <Container
          className="text-start"
          style={{ fontFamily: 'pangramregular' }}
        >
          <div className="aboutaone border">
            <h3>
              <span style={{ color: '#ed7745' }} className="stroke-head">
                About us
              </span>
            </h3>
            <div className="mb-5">
              <h5>Premium Brand for Furniture in Kerala</h5>
              <p>
                A One Furniture is the premium brand for furniture in Kerala
                retailing from furniture store located in Aroor which is nearly
                10 kms away from Kochi. We are the biggest wooden furniture
                dealers in Kochi, Kottayam and all over Kerala dealing with
                standard range of furniture sets and interior items. We cater
                our customers for all their furniture needs for their home or
                office at value prices, keeping luxury within reach of design
                conscious. From tables and chairs to storage, our modern
                furniture collections allow you to create a home that matches
                your lifestyle.
              </p>
              <br />
              <p>
                At A One Furniture we guarantee best quality furniture, crafted
                by our own design team, combining functionality with
                affordability. Every stage in crafting furniture's and other
                decorative pieces are full explained to our well-trained
                carpenters those who are stepping into work with Spanish
                Technology. Starting with the basics, Nature of wood, the tools
                and machineries are laid out within the factory premises with a
                highly qualified supervision by the interstate engineers to
                avoid any defect after manufacturing.
              </p>
            </div>
            <h3>
              <span style={{ color: '#ed7745' }} className="stroke-head">
                Our Mission & Vision
              </span>
            </h3>
            <div className="mb-5">
              <h5>To be the leading furniture store in Kerala</h5>
              <p>
                Our vision is to be the leading furniture store in Kerala that
                provide best quality furniture products and accessories.
                Customer satisfaction is our prime concern and we always do care
                the value of their money, by making sure that the product meet
                international quality standards at every stage of its
                manufacturing.
              </p>
              <br />
              <p>
                Our mission is to continuously manufacture and supply furniture
                products that satisfy the needs of our customers. We always put
                lots of effort to offer a range of furniture products at
                affordable prices, which add to comfort of life.
              </p>
            </div>
          </div>
          <BestSellers data="Popular Across Site" />
        </Container>
      </main>
      <footer>
        <FormSub />
        <CustomerStories />
        <FooterTop />
        <FooterMid />
      </footer>
    </>
  );
}
