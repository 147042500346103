import "./edit.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { typeListInputs } from "../../../../formSource";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";

const Edit = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { id } = useParams();
  const { data } = useFetch(`${apiStart}/api/${type}/${id}`);

  const [file, setFile] = useState("");
  const [text, setText] = useState("");
  const [preview, setPreview] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleClick = async () => {
    if (file === "" && text !== "") {
      await axios.put(
        `${apiStart}/api/${type}/${id}`,
        {
          name: text,
        },
        {
          withCredentials: true,
        }
      );
    } else if (file !== "" && text === "") {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("text", data.name);
      await axios.put(`${apiStart}/api/${type}/img/${id}`, formData, {
        withCredentials: true,
      });
    } else if (file !== "" && text !== "") {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("text", text);
      await axios.put(`${apiStart}/api/${type}/img/${id}`, formData, {
        withCredentials: true,
      });
    }

    window.location.href = `/admin/${type}`;
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>Edit {type} Products</h1>
        </div>
        <div className="main-set">
          <div className="text-center">
            <img
              className="img-fluid"
              src={file !== "" ? preview : data.image}
              alt="products"
            />
          </div>
          <div className="right">
            <Form onSubmit={handleClick}>
              <Form.Group className="formInput">
                <Form.Label htmlFor="file">
                  Image upload:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </Form.Label>
                <Form.Control
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Form.Group>

              <div className="" key={typeListInputs[0].id}>
                <Form.Group className="mb-3">
                  <Form.Label>{typeListInputs[0].label}</Form.Label>
                  <Form.Control
                    defaultValue={data.name}
                    type="text"
                    placeholder="Enter your product name"
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>
              </div>
              {text === "" && file === "" ? (
                <Button aria-label="edit product" disabled type="submit">
                  Submit
                </Button>
              ) : (
                <Button aria-label="edit product" type="submit">
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
