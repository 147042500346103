import React from 'react';
import { Container } from 'react-bootstrap';

export default function HeaderLow() {
  return (
    <>
      <Container>
        <nav>
          <div className="lowlist">
            <a aria-label="Living" href="/subCategory/living">
              Living
            </a>
            <a aria-label="Bedroom" href="/subCategory/bedroom">
              Bedroom
            </a>
            <a aria-label="Dining" v href="/subCategory/dining">
              Dining
            </a>
            <a aria-label="Storage" href="/subCategory/storage">
              Storage
            </a>
            <a aria-label="KidsRoom" href="/subCategory/kids">
              Kids Room
            </a>
            <a aria-label="Decor" href="/subCategory/decor">
              Decor
            </a>
            <a aria-label="Furnishing" href="/subCategory/furnishing">
              Furnishing
            </a>
            <a aria-label="Outdoor" href="/subCategory/outdoor">
              Outdoor
            </a>
          </div>
        </nav>
      </Container>
      <hr style={{ color: '4a4a4a' }} />
    </>
  );
}
