import "./orderScreen.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import axios from "axios";
import { apiStart } from "../../../apiurl/apiData";

const OrderScreen = () => {
  const navigate = useNavigate();

  const { data } = useFetch(`${apiStart}/api/order/`);
  const handleView = (e) => {
    navigate(`/admin/orders/${e}`);
  };
  const handleDelete = async (e) => {
    navigate("/admin");
    await axios.delete(`${apiStart}/api/order/${e}`, {
      withCredentials: true,
    });
    navigate("/admin/orders");
  };
  return (
    <Container fluid className="home">
      <Row>
        <Col key="1" md={3}>
          <Sidebar />
        </Col>
        <Col md={9} key="2" className="homeContainer">
          <div className="datatable">
            <div className="datatableTitle">Orders</div>
            <Table style={{ width: "70vw" }} striped bordered hover>
              <thead>
                <tr>
                  <th key="1">#</th>
                  <th key="2">Name</th>
                  <th key="3">Email</th>
                  <th key="4">Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {data.map((e, i) => (
                  <tr>
                    <td key="1">{i + 1}</td>
                    <td key="2">{e.name}</td>
                    <td key="3">{e.email}</td>
                    <td key="4">{e.number}</td>
                    <td key="5" className="d-flex">
                      <Button
                        aria-label="View order"
                        onClick={() => handleView(e._id)}
                        variant="success"
                        className="viewButton"
                      >
                        View
                      </Button>

                      <Button
                        aria-label="Delete Order"
                        variant="danger"
                        className="deleteButton"
                        onClick={() => handleDelete(e._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="cellAction"></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderScreen;
