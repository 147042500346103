import React, { useEffect } from 'react';
import './Social.scss';

export default function Social() {
  useEffect(() => {
    function hide() {
      document.getElementById('fixedbanner').className = 'hide';
      document.getElementById('whatsbanner').className = 'popsharewhat';
    }
    setTimeout(hide, 5000);
  });

  return (
    <>
      <div id="fixedbanner" className="popshare ">
        <a
          aria-label="Chat on WhatsApp"
          href="https://web.whatsapp.com/send?phone=+919072777555?text=Hi%20I%20Need%20Aone%20Assistance"
          title="whatsapp"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-whatsapp fa-2x circle-bg-g"></i>
        </a>
        <a
          aria-label="Chat on facebook"
          href="https://www.facebook.com/aonefurnitureonline?mibextid=LQQJ4d"
          title="facebook"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-facebook fa-2x circle-bg-fb"></i>
        </a>
        <a
          aria-label="connect instagram"
          href="https://instagram.com/a_one_furniture_?igshid=YmMyMTA2M2Y="
          title="pinterest"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-instagram fa-2x circle-bg-p"></i>
        </a>
        <a
          aria-label="chat on twitter"
          href="mailto:aonefrunitures@gmail.com"
          title="google-plus"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-envelope fa-2x circle-bg-tw"></i>
        </a>{' '}
      </div>

      <div div id="whatsbanner" className=" hide">
        <a
          aria-label="Chat on WhatsApp"
          href="whatsapp://send?phone=+919072777555?text=Hi I Need Aone Assistance"
          title="whatsapp"
          target="_blank"
          rel="noreferrer"
          className="whatasd"
        >
          <i className="fa fa-whatsapp circle-bg-g"></i>
        </a>
      </div>
    </>
  );
}
