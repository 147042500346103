import { configureStore } from "@reduxjs/toolkit";
import { adminReducer } from "./Reducers/Admin";
import { subscribeReducer } from "./Reducers/Subscribe";

const store = configureStore({
  reducer: {
    admin: adminReducer,
    subscribe: subscribeReducer,
  },
});

export default store;
