import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  subscribe: false,
};

export const subscribeReducer = createReducer(initialState, {
  subscribed: (state) => {
    state.subscribe = true;
  },
  notsubscribed: (state) => {
    state.subscribe = false;
  },
});
