import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function FooterMid() {
  return (
    <div className="footer-mid">
      <Container className="mid-align">
        {window.innerWidth >= 780 ? (
          <>
            <Row>
              <Col>
                <h2>
                  <span className="stroke-head">About</span>
                </h2>
                <p>
                  A One Furniture is the premium brand for furniture in Kerala,
                  with exclusive furniture shops in Kochi and Aroor. We offer
                  best quality furniture, crafted by our own design team,
                  combining functionality with affordability. Our modern
                  furniture collections allow you to create a home that matches
                  your lifestyle.
                </p>
              </Col>
              <Col>
                <h3>
                  {" "}
                  <span className="stroke-head">Contact</span>{" "}
                </h3>
                <div>
                  <span>
                    Aone Furniture
                    <br />
                  </span>
                  <span>
                    N. Nagar P.O, Cherthala,
                    <br />
                  </span>
                  <span>
                    Arookutty - 688 559
                    <br />
                  </span>
                  <span>
                    Fax (0478) 2878644
                    <br />
                  </span>
                  <span>
                    Tel (0478) 2875786
                    <br />
                  </span>
                  <span>aonefurniture555@gmail.com</span>
                </div>
              </Col>
              <Col>
                <h3>
                  <span className="stroke-head ">Explore More</span>
                </h3>
                <h6>
                  <a
                    aria-label="gmap"
                    href="https://www.google.com/maps/place/A+One+Furniture/@9.855354,76.3045441,17z/data=!3m1!4b1!4m5!3m4!1s0x3b0871a545920e9b:0xe944d00dc4ef71d0!8m2!3d9.8553487!4d76.3067328"
                  >
                    <svg
                      style={{ width: "24px", color: "darkgrey" }}
                      xmlns="https://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "black",
                      }}
                    >
                      Find Store Location
                    </span>
                  </a>
                </h6>
                <h6>
                  <a aria-label="contactnumber" href="tel:04782875786">
                    <svg
                      style={{ width: "23px", color: "darkgrey" }}
                      xmlns="https://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    <span style={{ marginLeft: "5px", color: "black" }}>
                      Call Us
                    </span>
                  </a>
                </h6>
                <h6>
                  <a aria-label="about" href="/about-us">
                    <svg
                      style={{ width: "24px", color: "darkgrey" }}
                      xmlns="https://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                      />
                    </svg>
                    <span style={{ marginLeft: "5px", color: "black" }}>
                      About us
                    </span>
                  </a>
                </h6>
                <h6>
                  <a aria-label="help" href="/help">
                    <svg
                      style={{ width: "24px", color: "darkgrey" }}
                      xmlns="https://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                      />
                    </svg>
                    <span style={{ marginLeft: "5px", color: "black" }}>
                      Help Center
                    </span>
                  </a>
                </h6>
              </Col>
              <Col>
                <h3>
                  <span className="stroke-head ">Stay Connected</span>
                </h3>
                <h5>
                  <a
                    aria-label="whatsapp"
                    className="anchor-footer"
                    href="https://web.whatsapp.com/send?phone=+919072777555?text=Hi%20I%20Need%20Aone%20Assistance"
                  >
                    <i
                      style={{ color: "#25D366" }}
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                    ></i>
                    <span style={{ marginLeft: "5px" }}>Whatsapp</span>
                  </a>
                  <br />
                </h5>
                <h5>
                  <a
                    aria-label="facebook"
                    className="anchor-footer"
                    href="https://www.facebook.com/aonefurnitureonline?mibextid=LQQJ4d"
                  >
                    <i
                      style={{ color: "#4267B2" }}
                      className="fa fa-facebook-official"
                      aria-hidden="true"
                    ></i>
                    <span style={{ marginLeft: "5px" }}>Facebook</span>
                  </a>
                  <br />
                </h5>
                <h5>
                  <a
                    aria-label="linkedin"
                    className="anchor-footer"
                    href="https://www.linkedin.com/company/a-one-furniture"
                  >
                    <i
                      style={{ color: "#0A66C2" }}
                      className="fa fa-linkedin-square"
                      aria-hidden="true"
                    ></i>
                    <span style={{ marginLeft: "5px" }}>Linkedin</span>
                  </a>
                  <br />
                </h5>
                <h5>
                  <a
                    aria-label="Twitter"
                    className="anchor-footer"
                    href="https://twitter.com/AoneFurniture"
                  >
                    <i
                      style={{ color: "#1DA1F2" }}
                      className="fa fa-twitter-square"
                      aria-hidden="true"
                    ></i>
                    <span style={{ marginLeft: "5px" }}>Twitter</span>
                  </a>
                </h5>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Col>
              <Row className="">
                <h2>
                  <span className="stroke-head">About</span>
                </h2>
                <p>
                  A One Furniture is the premium brand for furniture in Kerala,
                  with exclusive furniture shops in Kochi and Aroor. We offer
                  best quality furniture, crafted by our own design team,
                  combining functionality with affordability. Our modern
                  furniture collections allow you to create a home that matches
                  your lifestyle.
                </p>
              </Row>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    {" "}
                    <span className="stroke-head">Contact</span>{" "}
                  </h3>
                  <div>
                    <span>
                      Aone Furniture
                      <br />
                    </span>
                    <span>
                      N. Nagar P.O, Cherthala,
                      <br />
                    </span>
                    <span>
                      Arookutty - 688 559
                      <br />
                    </span>
                    <span>
                      Fax (0478) 2878644
                      <br />
                    </span>
                    <span>
                      Tel (0478) 2875786
                      <br />
                    </span>
                    <span>aonefurniture555@gmail.com</span>
                  </div>
                </div>
                <div>
                  <h3>
                    <span className="stroke-head ">Stay Connected</span>
                  </h3>
                  <h5>
                    <a
                      aria-label="whatsapp"
                      className="anchor-footer"
                      href="https://whatsapp.com"
                    >
                      <i
                        style={{ color: "#25D366" }}
                        className="fa fa-whatsapp"
                        aria-hidden="true"
                      ></i>
                      <span style={{ marginLeft: "5px" }}>Whatsapp</span>
                    </a>
                    <br />
                  </h5>
                  <h5>
                    <a
                      aria-label="facebook"
                      className="anchor-footer"
                      href="https://www.facebook.com/aonefurnitureonline?mibextid=LQQJ4d"
                    >
                      <i
                        style={{ color: "#4267B2" }}
                        className="fa fa-facebook-official"
                        aria-hidden="true"
                      ></i>
                      <span style={{ marginLeft: "5px" }}>Facebook</span>
                    </a>
                    <br />
                  </h5>
                  <h5>
                    <a
                      aria-label="linkedin"
                      className="anchor-footer"
                      href="https://www.linkedin.com/company/a-one-furniture"
                    >
                      <i
                        style={{ color: "#0A66C2" }}
                        className="fa fa-linkedin-square"
                        aria-hidden="true"
                      ></i>
                      <span style={{ marginLeft: "5px" }}>Linkedin</span>
                    </a>
                    <br />
                  </h5>
                  <h5>
                    <a
                      aria-label="Twitter"
                      className="anchor-footer"
                      href="https://twitter.com/AoneFurniture"
                    >
                      <i
                        style={{ color: "#1DA1F2" }}
                        className="fa fa-twitter-square"
                        aria-hidden="true"
                      ></i>
                      <span style={{ marginLeft: "5px" }}>Twitter</span>
                    </a>
                  </h5>
                </div>
              </div>
            </Col>
          </>
        )}
      </Container>
    </div>
  );
}
