import React from 'react';

export default function FooterTop() {
  return (
    <div style={{ backgroundColor: 'rgba(231, 227, 227, 0.2)' }}>
      <div
        style={{ backgroundColor: 'rgba(231, 227, 227, 0.1)' }}
        className="container footer-top"
      >
        <hr />
        <h5>
          Buy Furniture Online at AONEfurniture- India’s Finest One-Stop
          Destination for All Your Needs
        </h5>
        <p>
          Getting the wooden furniture online in India, which perfectly blends
          with your home decor is now easy. AONEfurniture provides you with
          ready-made and customized solid wood furniture online. At
          AONEfurniture, we are committed to serve our customers the exclusive
          range in home furniture products like sofa set, coffee tables,
          wardrobes, dining tables, all crafted from premium-quality solid-wood.
          Along with this, you can explore our home decor range which includes
          wall art, photo frames, indoor plants, tableware, glassware, kitchen
          organizer and many more are just few clicks away from you. We offer
          you a huge variety of online furniture to choose from. You can browse
          among various designs, dimensions, colors and finish options, all at
          your convenience. From modern to traditional styles, contemporary to
          loft ones, space-saving furniture pieces to huge ones, we provide
          every type of modern furniture online to match your home needs. Take a
          pick from the exquisite collection of home furniture or get it
          customized as per your requirements, all possible at AONEfurniture.
        </p>
        <h5>How to purchase products from AONEfurniture </h5>
        <p>
          Select your desired product, and proceed to checkout by giving your
          personal and contact details. Our concerned dealers will contact you
          shortly. You can directly chat with us using WhatsApp and email.
        </p>
      </div>
    </div>
  );
}
