import React from 'react';

export default function Header(props) {
  const { slug } = props;
  const url = `/images/categoryHead/${slug}.jpg`;
  return (
    <div className="cont-1">
      <div className="cont-img">
        <div className="imasd">
          <link rel="preload" as="image" href={url} />
          <img className="img-animation" src={url} alt={slug} />
        </div>
      </div>
      <div className="sofa-head">
        <h1>COMFORT OBSESSION</h1>
        <h5>SOFT & COZY VIBES</h5>
      </div>
    </div>
  );
}
