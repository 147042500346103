import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function Slide() {
  return window.innerWidth >= 780 ? (
    <div className="slide-pics">
      <Carousel indicators={false} slide={false}>
        <Carousel.Item interval={1000}>
          <link rel="preload" as="image" href="/images/slide/1.png" />
          <img
            className="d-block  slide-img"
            src="/images/slide/1.png"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block  slide-img"
            src="/images/slide/2.png"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img
            className="d-block  slide-img"
            src="/images/slide/3.png"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block  slide-img"
            src="/images/slide/4.png"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div className="big-differ">
        <h3>
          Make Your Dream Home
          <br /> Happen...
        </h3>
        <a aria-label="newlaunch catg" className="shop-now" href="#newlnch">
          Shop Now
        </a>
      </div>
    </div>
  ) : (
    <div className="slide-pics">
      <Carousel indicators={false} slide={false}>
        <Carousel.Item interval={1500}>
          <link rel="preload" as="image" href="/images/slide/1.png" />
          <img
            className="d-block  slide-img"
            src="/images/slide/5.jpg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img
            className="d-block  slide-img"
            src="/images/slide/6.jpg"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img
            className="d-block  slide-img"
            src="/images/slide/7.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img
            className="d-block  slide-img"
            src="/images/slide/8.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div className="big-differ">
        <h3>
          Make Your Dream Home
          <br /> Happen...
        </h3>
        <a aria-label="newlaunch catg" className="shop-now" href="#newlnch">
          Shop Now
        </a>
      </div>
    </div>
  );
}
