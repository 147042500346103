import "./Widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import useFetch from "../../../hooks/useFetch";
import { apiStart } from "../../../apiurl/apiData";

const Widget = ({ type }) => {
  const { data } = useFetch(`${apiStart}/api/count/`);
  let datas;

  switch (type) {
    case "Products":
      datas = {
        title: "PRODUCTS",
        link: "See all products",
        amount: data[0],
        anchor: "/admin/products",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      datas = {
        title: "ORDERS",
        link: "View all orders",
        amount: data[3],
        anchor: "/admin/orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "bestseller":
      datas = {
        title: "BEST SELLER",
        link: "See products",
        anchor: "/admin/bestseller",
        amount: data[1],
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "newlaunch":
      datas = {
        title: "NEW LAUNCH",
        link: "See products",
        anchor: "/admin/newlaunch",
        amount: data[2],
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    case "subscription":
      datas = {
        title: "SUBSCRIPTION",
        link: "See subscribers",
        anchor: "/admin/subscriptions",
        amount: data[4],
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{datas.title}</span>
        <span className="counter">{datas.amount}</span>
        <span className="link">
          <a aria-label="widget" className="anchor-prod" href={datas.anchor}>
            {datas.link}
          </a>
        </span>
      </div>
      <div className="right">{datas.icon}</div>
    </div>
  );
};

export default Widget;
