import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import Sidebar from "../../../components/sidebar/Sidebar";
import "./view.scss";
import { apiStart } from "../../../../apiurl/apiData";

export default function View() {
  const { id } = useParams();
  const { data } = useFetch(`${apiStart}/api/order/${id}`);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>View Order</h1>
        </div>
        <div className="main-set">
          <ol>
            <li key="1">
              <span className="list-set">1)Name:</span>
              <span className="list-data">{data.name}</span>
            </li>
            <li key="2">
              <span className="list-set">2)Email:</span>
              <span className="list-data">{data.email}</span>
            </li>
            <li key="3">
              <span className="list-set">3)Phone No.:</span>
              <span className="list-data">{data.number}</span>
            </li>
            <li key="4">
              <span className="list-set">4)Address:</span>
              <span className="list-data">{data.address1}</span>
              <br />
              <span className="list-data address">{data.address2}</span>
              <br />
              <span className="list-data address">{data.address3}</span>
              <br />
              <span className="list-data address">{data.pincode}</span>
              <br />
            </li>
            <li key="5">
              <img src={data.image} alt="image" className="img-fluid" />
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
