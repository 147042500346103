import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";

const NewProduct = ({ inputs, title }) => {
  const categoryoptions = [
    "sofa-sets",
    "beds",
    "table",
    "recliner-sofa",
    "wardrobes",
    "office-table",
    "grandfather-clock",
    "chair-dining-set",
    "book-shelves",
    "coffee-table",
    "carpet",
    "tv-stand",
    "home-decor",
    "lamps-&-lighting",
    "crokery-shelf",
    "bed-side-table",
    "shoe-rack",
    "dressing-tables",
    "modular-kitchen",
    "stool",
    "sitout-chair",
    "diwan",
    "bench",
    "dispaly-unit",
  ];
  const subCategoryoptions = [
    "living",
    "bedroom",
    "dining",
    "storage",
    "kidsroom",
    "decor",
    "furnishing",
    "outdoor",
  ];

  const navigate = useNavigate();

  const [file, setFile] = useState("");
  const [category, setCategory] = useState(categoryoptions[0]);
  const [subCategory, setSubCategory] = useState(subCategoryoptions[0]);
  const [name, setName] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  console.log(category, subCategory, name);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("image", file);
    formData.append("category", category);
    formData.append("subCategory", subCategory);
    formData.append("name", name);
    await axios.post(`${apiStart}/api/products/`, formData, {
      withCredentials: true,
    });
    window.location.href = `/admin/products`;
  };
  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="newContainer">
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt="products"
                className="img-fluid"
              />
            </div>
            <div className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="formInput">
                  <Form.Label htmlFor="file">
                    Image upload:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </Form.Label>
                  <Form.Control
                    id="file"
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Form.Group>

                <div className="">
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Category:</Form.Label>
                      <Form.Select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        {categoryoptions.map((e, i) => (
                          <option key={i} value={e}>
                            {e}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Sub Category:</Form.Label>
                      <Form.Select
                        value={subCategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                      >
                        {subCategoryoptions.map((e, i) => (
                          <option key={i} value={e}>
                            {e}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        type="text"
                        name="text"
                        placeholder="Enter product name"
                      />
                    </Form.Group>
                  </>
                </div>
                <Button aria-label="new product" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default NewProduct;
