import React, { useState } from 'react';
import { Form, Button, InputGroup, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function MobileHead() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [search, setSearch] = useState('');
  const handleSearch = () => {
    navigate(`/search/${search}`);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <Button
            aria-label="side tab"
            className="bg-light border-light p-2"
            variant="primary"
            onClick={handleShow}
          >
            <i
              style={{ color: 'darkgrey', fontSize: '28px' }}
              className="fa fa-bars"
              aria-hidden="true"
            ></i>
          </Button>
          <h5 className="ml-2 mt-1">
            <a aria-label="home" href="/">
              <img
                style={{ width: '50px', height: '50px' }}
                className="img-fluid logomid"
                src="/logo.png"
                alt="logo"
              />
            </a>
          </h5>
        </div>
        <div className="d-flex">
          <a
            aria-label="wishlist"
            href="/wishlist"
            className=" whislist-cart icons mt-1"
          >
            <i>
              <svg width="28" height="28" viewBox="0 0 26 26" fill="none">
                <g transform="translate(0 -5.713)">
                  <path
                    d="M21.964,7.925c-.321-3.535-2.823-6.1-5.954-6.1a5.918,5.918,0,0,0-5.07,2.922A5.7,5.7,0,0,0,5.99,1.826C2.86,1.826.358,4.39.037,7.925a6.289,6.289,0,0,0,.187,2.318A9.942,9.942,0,0,0,3.27,15.326l7.665,6.955,7.8-6.955a9.943,9.943,0,0,0,3.046-5.083,6.3,6.3,0,0,0,.187-2.317Zm-1.011,2.124A9.092,9.092,0,0,1,18.164,14.7l-7.225,6.446L3.839,14.7a9.1,9.1,0,0,1-2.791-4.65A5.5,5.5,0,0,1,.872,8.06l.006-.043C1.153,4.92,3.3,2.672,5.99,2.672a4.96,4.96,0,0,1,4.557,3.18l.39.924.39-.924A5.112,5.112,0,0,1,16.01,2.673c2.687,0,4.837,2.248,5.118,5.385A5.489,5.489,0,0,1,20.953,10.049Z"
                    transform="translate(0 3.887)"
                    fill="#4a4a4a"
                  ></path>
                </g>
              </svg>
            </i>
          </a>
        </div>

        <Offcanvas style={{ width: '60vw' }} show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img className="img-fluid logomid" src="/logo.png" alt="logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="sidebar-nav">
            <div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="Living"
                  style={{ color: '#464545' }}
                  href="/subCategory/living"
                >
                  Living
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="bedroom"
                  style={{ color: '#464545' }}
                  href="/subCategory/bedroom"
                >
                  Bedroom
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="dining"
                  style={{ color: '#464545' }}
                  href="/subCategory/dining"
                >
                  Dining
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="storage"
                  style={{ color: '#464545' }}
                  href="/subCategory/storage"
                >
                  Storage
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="kidsroom"
                  style={{ color: '#464545' }}
                  href="/subCategory/kids"
                >
                  Kids Room
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="decor"
                  style={{ color: '#464545' }}
                  href="/subCategory/decor"
                >
                  Decor
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="furnishing"
                  style={{ color: '#464545' }}
                  href="/subCategory/furnishing"
                >
                  Furnishing
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <a
                  aria-label="outdoor"
                  style={{ color: '#464545' }}
                  href="/subCategory/outdoor"
                >
                  Outdoor
                </a>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
            </div>

            <div className="mobile-headall mt-3">
              <div>
                <a
                  aria-label="gmap"
                  href="https://www.google.com/maps/place/A+One+Furniture/@9.855354,76.3045441,17z/data=!3m1!4b1!4m5!3m4!1s0x3b0871a545920e9b:0xe944d00dc4ef71d0!8m2!3d9.8553487!4d76.3067328"
                >
                  <svg
                    style={{ width: '20px' }}
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                  <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                    Find Store Location
                  </span>
                </a>
              </div>
              <div>
                <a aria-label="mobile number" href="tel:04782875786">
                  <svg
                    style={{ width: '20px' }}
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <span style={{ marginLeft: '5px' }}>0478-2875786</span>
                </a>
              </div>
              <div>
                <a aria-label="about" href="/about">
                  <svg
                    style={{ width: '20px' }}
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>
                  <span style={{ marginLeft: '5px' }}>About us</span>
                </a>
              </div>
              <div>
                <a aria-label="help" href="/help">
                  <svg
                    style={{ width: '20px' }}
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>
                  <span style={{ marginLeft: '5px' }}>Help Center</span>
                </a>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div>
        <InputGroup style={{ width: '97vw' }} className="m-2 formmobilewidth">
          <Form.Control
            className="border border-black border-end-0"
            placeholder="Search Product and more ..."
            aria-describedby="basic-addon2"
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            aria-label="search product"
            className="border border-black border-start-0"
            variant="outline-secondary"
            id="button-addon2"
            onClick={handleSearch}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="18.762"
              height="20"
              viewBox="0 0 18.762 20"
            >
              <path
                d="M19.461,18.217l-4.625-4.951a8.191,8.191,0,0,0,1.841-5.191A7.972,7.972,0,0,0,8.83,0,7.972,7.972,0,0,0,.984,8.075,7.972,7.972,0,0,0,8.83,16.15a7.611,7.611,0,0,0,4.5-1.461l4.66,4.988a1,1,0,0,0,1.447.029A1.076,1.076,0,0,0,19.461,18.217ZM8.83,2.107a5.892,5.892,0,0,1,5.8,5.968,5.892,5.892,0,0,1-5.8,5.968,5.892,5.892,0,0,1-5.8-5.968A5.892,5.892,0,0,1,8.83,2.107Z"
                transform="translate(-0.984)"
                fill="#4a4a4a"
              ></path>
            </svg>
          </Button>
        </InputGroup>
      </div>
    </div>
  );
}
